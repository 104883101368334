import React, { useEffect, useState } from "react";
import { Box, Grid, CircularProgress, Button, Modal, Typography, Autocomplete, TextField } from "@mui/material";
import { Form, Header, Icon } from "semantic-ui-react";
import ArgusLogo from "../../img/logo.svg";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Link } from "react-router-dom";
import "./style.css";
import { getTecfinityAccountData } from "../../main-component/PageFunctions/tecfinityFunctions";
import { createTradeAccount } from "../../main-component/PageFunctions/userFunctions";
import { useAuth0 } from "@auth0/auth0-react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { fetchWebsiteData } from "../../main-component/PageFunctions/commonFunctions";
import { find } from "lodash";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minHeight: "50%",
	width: "25%",
	bgcolor: '#FFFFFF',
	boxShadow: 24,
	p: 4
};

const modalState = {
	search: 1,
	searching: 2,
	accountFound: 3,
	accountNotFound: 4,
	creating: 5,
	accountCreated: 6,
	accountNotCreated: 7,
	error: 8
};

const TecfinityAccountCreationModal = ({
	open,
	setClose,
	userToEdit,
	userId,
	reloadPage
}) => {
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [currentModalState, setCurrentModalState] = useState(modalState.search);
	const [modalError, setModalError] = useState("");
	const [tecfinityAccountFound, setTecfinityAccountFound] = useState(null);
	const [websiteChosen, setWebsiteChosen] = useState(null);
	const [websites, setWebsites] = useState([]);
	const [websiteOptions, setWebsiteOptions] = useState([]);
	const [websiteSearchValue, setWebsiteSearchValue] = useState("");
	const [websiteError, setWebsiteError] = useState(null);

	const schema = yup.object({
		tecfinityAccount: yup.string().required("Enter the Tecfinity Account number to search")
	}).required();
	const {control, watch, getValues, setValue, handleSubmit, formState: {errors}, setError, clearErrors} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			tecfinityAccount: ""
		}
	});

	const getTecfinityAccountDataAsync = async (accountNumber) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await getTecfinityAccountData({
			auth0Token,
			setTecfinityAccountFound,
			setModalError,
			accountNumber,
			setStatusInProgress: () => setCurrentModalState(modalState.searching),
			setStatusSuccess: () => setCurrentModalState(modalState.accountFound),
			setStatusFailed: () => setCurrentModalState(modalState.accountNotFound),
			setStatusError: () => setCurrentModalState(modalState.error)
		});
	};

	const getWebsiteDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchWebsiteData({
			auth0Token,
			setWebsites,
			setStatusInProgress: () => setCurrentModalState(modalState.accountFound),
			setStatusSuccess: () => setCurrentModalState(modalState.accountFound),
			setStatusFailed: () => setCurrentModalState(modalState.error),
			setStatusError: () => setCurrentModalState(modalState.error)
		});
	};

	const createTradeAccountAsync = async ({
		websiteId,
		accountNumber
	}) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await createTradeAccount({
			auth0Token,
			userId,
			websiteId,
			accountNumber,
			setStatusInProgress: () => setCurrentModalState(modalState.creating),
			setStatusSuccess: () => setCurrentModalState(modalState.accountCreated),
			setStatusFailed: () => setCurrentModalState(modalState.accountNotCreated),
			setStatusError: () => setCurrentModalState(modalState.error)
		});
	};

	useEffect(() => {
		if (!userToEdit) {
			setCurrentModalState(modalState.error);
			setModalError("Could not load user information. Please try again later or contact support.");
		}
	}, []);

	useEffect(() => {
		if (!!websites?.length) {
			setWebsiteOptions(websites.map(x => x.name).sort());
			setWebsiteChosen(websites[0].name);
		}
	}, [websites]);

	useEffect(() => {
		if (modalState.accountFound === currentModalState) {
			getWebsiteDataAsync();
		}

		if (currentModalState === modalState.accountLinked) {
			reloadPage();
		}
	}, [currentModalState]);

	const submitForm = async values => {
		if (!!values.tecfinityAccount) {
			await getTecfinityAccountDataAsync(values.tecfinityAccount);
		}
	};

	const resetModal = () => {
		setValue("tecfinityAccount", "");
		setCurrentModalState(modalState.search);
		setModalError("");
	};

	const closeModal = () => {
		resetModal();
		setClose();
	};

	const handleConfirmTecfinityAccountCreation = async () => {
		const websiteId = find(websites, x => x.name === websiteChosen, 0).websiteDetailsId;
		if (!!websiteId) {
			await createTradeAccountAsync({
				websiteId,
				accountNumber: tecfinityAccountFound.accountNumber,
			});
		}
	};

	return (
		<Modal open={open} onClose={closeModal}>
			<Box sx={style}>
				<Box textAlign="center" mb={2}>
					<img src={ArgusLogo} alt="Argus Logo" style={{ height: 50 }} />
					<Typography variant="h6" mt={2}>Create Trade Account</Typography>
				</Box>
				{(currentModalState === modalState.search || currentModalState === modalState.searching) &&
					<Box textAlign="center">
						<Typography variant="body2" mb={2}>
							Enter the Tecfinity number that you would like to link to this account and hit
							confirm to create the link.
						</Typography>
						<Form onSubmit={handleSubmit(submitForm)}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Controller
										name="tecfinityAccount"
										control={control}
										render={({field: {onChange, value}}) => (
											<Form.Input
												label="Tecfinity Account"
												type="text"
												onChange={onChange}
												value={value}
												disabled={currentModalState === modalState.searching}
												loading={currentModalState === modalState.searching}
												placeholder="Enter the Tecfinity account to search for..."/>
										)}
									/>
									<ErrorMessage
										errors={errors}
										name="tecfinityAccount"
										render={({message}) => <p
											className="b4 color-red mb-2">{message}</p>}
									/>
								</Grid>

								<Grid item xs={12}>
									<Grid container spacing={2} justifyContent="flex-end">
										<Grid item>
											<Button onClick={closeModal} color="error" disabled={currentModalState === modalState.searching}>
												Cancel
											</Button>
										</Grid>
										<Grid item>
											<Button
												type="submit"
												variant="contained"
												color="primary"
												disabled={currentModalState === modalState.searching}
												loading={currentModalState === modalState.searching}
											>
												Submit
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Form>
					</Box>
				}
				{(currentModalState === modalState.accountFound || currentModalState === modalState.creating) && !!tecfinityAccountFound &&
					<Box textAlign="center">
						<Typography variant="body2" mb={2}>
							The Tecfinity account you searched for was found. These are the details of the
							accounts that will be linked:
						</Typography>
						<Box mb={2}>
							<Typography variant="body1"><b>Argus Account</b></Typography>
							<Typography variant="body2"><b>Contact:</b> {`${userToEdit.firstName} ${userToEdit.lastName}`}</Typography>
							<Typography variant="body2"><b>Email:</b> {userToEdit.email ?? "N/A"}</Typography>
						</Box>
						<Icon name="linkify" size="large"/>
						<Box mb={2} mt={2}>
							<Typography variant="body1"><b>Tecfinity Account</b></Typography>
							<Typography variant="body2"><b>Name:</b> {tecfinityAccountFound.name ?? "N/A"}</Typography>
							<Typography variant="body2"><b>Contact:</b> {tecfinityAccountFound.contact ?? "N/A"}</Typography>
							<Typography variant="body2"><b>Email:</b> {tecfinityAccountFound.email ?? "N/A"}</Typography>
						</Box>
						{!!websiteOptions &&
							<Box mb={2} mt={2}>
								<Autocomplete
									value={websiteChosen}
									onChange={(event, newValue) => {
										setWebsiteChosen(newValue);
										setWebsiteError(!newValue);
									}}
									inputValue={websiteSearchValue}
									onInputChange={(event, newInputValue) => {
										setWebsiteSearchValue(newInputValue);
									}}
									options={websiteOptions}
									renderInput={(params) => <TextField {...params} size="small" error={websiteError} helperText={!!websiteError ? "Please choose a website" : null} label="Website" />}
								/>
							</Box>
						}
						<Box textAlign="right">
							<Link className="argus-modal-link" to="#" onClick={resetModal}>
								Search again
							</Link>
						</Box>
						<Grid container mt={2} mb={2} spacing={2} justifyContent="flex-end">
							<Grid item>
								<Button onClick={closeModal}
								        color="error"
								        disabled={currentModalState === modalState.creating}
								        loading={currentModalState === modalState.creating}>
									Cancel
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									onClick={handleConfirmTecfinityAccountCreation}
									disabled={currentModalState === modalState.creating}
									loading={currentModalState === modalState.creating}
									startIcon={currentModalState === modalState.creating && <CircularProgress size="1rem" />}
								>
									Confirm
								</Button>
							</Grid>
						</Grid>
					</Box>
				}
				{currentModalState === modalState.accountCreated &&
					<Box textAlign="center">
						<CheckCircleOutlineIcon color="success" fontSize="large" />
						<Typography variant="h5" mt={2}>Account Linked</Typography>
						<Typography variant="body2">Tecfinity account successfully linked.</Typography>
						<Button variant="contained" color="primary" onClick={closeModal} sx={{ mt: 2 }}>
							Close
						</Button>
					</Box>
				}
				{currentModalState === modalState.accountNotCreated &&
					<Box textAlign="center">
						<CancelIcon color="error" fontSize="large" />
						<Typography variant="h5" mt={2}>Failed</Typography>
						<Typography variant="body2">Could not create link.</Typography>
						<Button variant="contained" color="primary" onClick={closeModal} sx={{ mt: 2 }}>
							Close
						</Button>
					</Box>
				}
				{currentModalState === modalState.error && !!modalError &&
					<Box textAlign="center">
						<Grid container sx={{ textAlign: "center" }}>
							<Grid item lg={12}>
								<Icon className="center-element" name="ban" color="red" size="massive"/>
							</Grid>
							<Grid item lg={12}>
								<Header as="h1" textAlign="center">Error</Header>
							</Grid>
							<Grid item lg={12}>
								<p className="b1">{modalError}</p>
							</Grid>
							<Grid item lg={12}>
								<Button type="button"
								        floated="right"
								        onClick={closeModal}>
									Close
								</Button>
							</Grid>
						</Grid>
					</Box>
				}
			</Box>
		</Modal>
	);
};

export default TecfinityAccountCreationModal;