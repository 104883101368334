import React from "react";
import { FormType } from "../../utils/constants";
import { Grid } from "semantic-ui-react";
import UserCreateEditForm from "../../forms/UserCreateEditForm";
import TecfinityAccountLinkModal from "../TecfinityAccountLinkModal";
import TecfinityAccountCreationModal from "../TecfinityAccountCreationModal";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

const UserCreateEdit = ({
	user,
	userId,
	tradeAccountToLink,
	tradeAccountToUpdate,
	orders,
	addresses,
	countries,
	provinces,
	salesPeople,
	formType,
	onBackClick,
	showTecfinityAccountLinkModal,
	showTecfinityAccountCreationModal,
	showTecfinityAccountUpdateModal,
	closeModals,
	reloadPage,
	submit,
	onLinkTecfinityAccountClick,
	onCreateTecfinityAccountClick,
	onUpdateTecfinityAccountClick,
	onConfirmUpdateClick,
	tecfinityAccountUpdateLoading
}) => {

	return (
		<div>
			<div className="argus-title-background mb-5">
				<div className="argus-title">
					User
				</div>
			</div>
			<Grid container>
				<Grid.Row verticalAlign="middle" textAlign="center">
					{formType === FormType.Edit && !!user &&
						<UserCreateEditForm
							formType={formType}
							user={user}
							orders={orders}
							addresses={addresses}
							provinces={provinces}
							countries={countries}
							salesPeople={salesPeople}
							goBack={onBackClick}
							submit={submit}
							onLinkTecfinityAccountClick={onLinkTecfinityAccountClick}
							onCreateTecfinityAccountClick={onCreateTecfinityAccountClick}
							onUpdateTecfinityAccountClick={onUpdateTecfinityAccountClick}
						/>
					}
				</Grid.Row>
				{!!showTecfinityAccountLinkModal &&
					<TecfinityAccountLinkModal
						open={showTecfinityAccountLinkModal}
						setClose={closeModals}
						userToEdit={user}
						userId={userId}
						tradeAccount={tradeAccountToLink}
						reloadPage={reloadPage}
					/>
				}
				{!!showTecfinityAccountCreationModal &&
					<TecfinityAccountCreationModal
						open={showTecfinityAccountCreationModal}
						setClose={closeModals}
						userToEdit={user}
						userId={userId}
						reloadPage={reloadPage}
					/>
				}
				{!!showTecfinityAccountUpdateModal &&
					<Dialog open={!!showTecfinityAccountUpdateModal} onClose={closeModals}>
						<DialogTitle>Confirm Update</DialogTitle>
						<DialogContent>
							<Typography variant="body1">
								Are you sure you want to update the information for this Tecfinity account: <b>{tradeAccountToUpdate.accountNumber}</b>?
							</Typography>
							<br />
							<Typography variant="caption" component="p">
								NOTE: This will update Tecfinity information for all users linked to this account?
							</Typography>
						</DialogContent>
						<DialogActions>
							<Button onClick={closeModals} color="error" disabled={tecfinityAccountUpdateLoading}>
								Cancel
							</Button>
							<Button onClick={onConfirmUpdateClick} color="primary" disabled={tecfinityAccountUpdateLoading}>
								Confirm
							</Button>
						</DialogActions>
					</Dialog>
				}
			</Grid>
		</div>
	);
};

export default UserCreateEdit;