import axios from "axios";
import { ApiHeaders } from "../utils/constants";
import { toast } from "react-toastify";

const url = process.env.REACT_APP_ORDER_API_URL;

export const getProvinces = async ({auth0Token}) => {
	try {
		const token = "";

		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/staticdata/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = "provinces";

		return await instance.get(requestUrl)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getCountries = async ({auth0Token}) => {
	try {
		const token = "";

		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/staticdata/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = "countries";

		return await instance.get(requestUrl)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getSalesPeople = async ({auth0Token}) => {
	try {
		const token = "";

		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/staticdata/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = "salespeople";

		return await instance.get(requestUrl)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getOrders = async ({pageSize, pageNumber, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const params = {
			limit: pageSize,
			skip: pageNumber - 1
		};

		return await instance.get("", {params: params})
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
				                     total: response.data.total
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getOrdersOverview = async ({
	pageSize,
	pageNumber,
	auth0Token,
	salesPersonCode,
	orderStatusId,
	orderPaymentStatusId,
	sortColumn,
	sortDirection
}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const skip = !!pageNumber ? Number(pageNumber) - 1 : 0;
		const searchParams = new URLSearchParams();
		searchParams.append("limit", pageSize);
		searchParams.append("skip", skip);
		if (!!salesPersonCode) searchParams.append("salesPersonCode", salesPersonCode);
		if (!!orderStatusId) searchParams.append("orderStatusId", orderStatusId);
		if (!!orderPaymentStatusId) searchParams.append("orderPaymentStatusId", orderPaymentStatusId);
		if (!!sortColumn) searchParams.append("sortColumn", sortColumn);
		if (!!sortDirection) searchParams.append("sortDirection", sortDirection);

		const requestUrl = `overviews?${searchParams.toString()}`;

		return await instance.get(requestUrl)
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
				                     total: response.data.total,
				                     skip: response.data.skip
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getDetailedOrder = async ({orderId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `detailed/${orderId}`;

		return await instance.get(requestUrl)
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
				                     total: response.data.total
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getOrderById = async ({orderId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const response = await instance.get(`${orderId}`);

		return response?.data ?? null;
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getOrdersByUserId = async ({userId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const response = await instance.get(`user/${userId}`);

		return response?.data ?? null;
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getDetailedOrderById = async ({orderId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const response = await instance.get(`detailed/${orderId}`);

		return response?.data ?? null;
	}
	catch (error) {
		console.error(error);
	}
};

export const markOrderItemAsFulfilled = async ({orderId, orderItemId, statusId, auth0Token}) => {
	try {
		const token = "";

		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}
		const response = await instance.put(`item-fulfillment-status/${orderId}/${orderItemId}/${statusId}`);

		return response?.data ?? null;
	}
	catch (e) {
		console.error(e);

	}
};

export const putOrderItemFulfillmentStatus = async ({orderId, body, auth0Token}) => {
	try {
		const token = "";

		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}
		const response = await instance.put(`fulfill-items/${orderId}`, body);

		return response?.data ?? null;
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const updateOrderPaymentStatus = async ({orderId, auth0Token}) => {
	try {
		const token = "";

		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}
		const response = await instance.put(`payment-status/${orderId}`);

		return response?.data ?? null;
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const putOrderPaymentToPaid = async ({orderId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `mark-as-paid/${orderId}`;

		return await instance.put(requestUrl)
		                     .then((response) => {
			                     if (response.status === 204) return {
				                     error: false,
				                     success: true
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);

	}
};

export const getSalesOrderRequestData = async ({requestId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `sales-order-request/${requestId}`;

		return await instance.get(requestUrl)
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const postRetrySalesOrder = async ({requestId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `retry-sales-order/${requestId}`;

		return await instance.post(requestUrl)
		                     .then((response) => {
			                     if (response.status === 204) return {
				                     error: false,
				                     success: true
			                     };
			                     else {
				                     toast(response.data.message, {
					                     type: "error",
					                     autoClose: 5000,
					                     hideProgressBar: false,
					                     closeOnClick: true,
					                     pauseOnHover: true,
					                     draggable: true,
					                     progress: undefined
				                     });
									 return {
					                     error: true,
					                     success: false,
					                     message: response.data.message
				                     };
			                     }
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
				                     toast("Could not retry sales order", {
					                     type: "error",
					                     autoClose: 5000,
					                     hideProgressBar: false,
					                     closeOnClick: true,
					                     pauseOnHover: true,
					                     draggable: true,
					                     progress: undefined
				                     });
			                     }
		                     });
	}
	catch (e) {
		console.error(e);

	}
};

export const putCancelOrder = async ({orderId, auth0Token}) => {
	const token = "";
	const instance = axios.create({
		withCredentials: true,
		baseURL: `${url}/api/order/`,
		headers: {
			"Content-type": 'application/json',
			[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
		},
	});

	if (!!auth0Token) {
		instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
	}

	const requestUrl = `cancel/${orderId}`;

	return await instance.put(requestUrl)
	                     .then((response) => {
		                     if (response.status === 204) return {
			                     error: false,
			                     success: true
		                     };
		                     else return {
			                     error: true,
			                     success: false,
			                     message: response.data.message
		                     };
	                     })
	                     .catch((error) => {
		                     if (error.response) {
			                     console.error('Server Error:', error.response.status);
		                     }
		                     else if (error.request) {
			                     console.error('Network Error:', error.request);
		                     }
		                     else {
			                     console.error('Error:', error.message);
		                     }
	                     });
};