export const storage_vehicle = "9e63097a-6bd3-4060-b5f3-6e5cb7e73cde";
export const local_storage_token = "1c28ed04-5862-443a-80a8-5e0674a97651";
export const session_storage_token = "bacd1f55-e569-46a7-920b-c8d85474a17f";
export const local_storage_refresh_token = "185b0c5b-b52d-44c3-93d3-9e040643ad3b";
export const storage_user_id = "3182e2af-1983-4cb3-9088-74ed761b5491";
export const storage_user_email = "aa14af4b-7a1a-4cd1-86f6-431ae0edb81a";
export const storage_user_firstName = "46860585-d276-4994-b103-34040c49fa97";
export const storage_user_lastName = "b1339e1f-45a9-4ae1-959c-3e5917680522";
export const storage_user_tecfinity_account_numbers = "873124a9-84a1-434d-b657-5b7253503741";
export const storage_user_tecfinity_account = "03ed6615-918f-42eb-a8e8-ec6f48e7c969";
export const storage_user_tecfinity_account_number = "97b866f9-6fc0-4eed-8a27-3ea94093158e";
export const storage_user_tecfinity_name = "8519b808-8f09-4950-8fb5-c949b6d8f61e";
export const storage_user_tecfinity_category = "a5c5bef5-67f2-4fbf-b0c7-48c3cc49885c";

export const OrderNumberPrefix = "ARG";

export const LoggedInStatus = Object.freeze({
	NotChecked: Symbol(1000),
	Yes: Symbol(1001),
	Refreshed: Symbol(1002),
	No: Symbol(1003)
});

export const PromotionsPageView = Object.freeze({
	Promotions: Symbol(1000),
	Products: Symbol(1001)
});

export const PaymentType = {
	AccountCredit: "6fab1f25-5c4b-4681-9aa7-c6f245ba039e",
	PayFast: "b4ef31f0-05e3-49ab-940f-fe42ddaa6e89",
	EFT: "87c316c5-1b31-45e8-a3af-c39f4385436a",
	CreditCardOnCollection: "a9ca4819-da6a-4843-9d95-670c70ae7322"
};

export const PaymentTypes =[
	{
		name: "Account Credit",
		value: PaymentType.AccountCredit
	},
	{
		name: "EFT",
		value: PaymentType.EFT
	},
	{
		name: "PayFast",
		value: PaymentType.PayFast
	},
	{
		name: "Credit Card on Collection",
		value: PaymentType.AccountCredit
	}
];

export const PaymentStatus = {
	Complete: "417c2635-24d9-4687-9032-a679dbee6e74",
	Failed: "ebb5979d-5645-4e8a-8e20-33870b91a587",
	Pending: "810b136b-0b48-4756-9196-a3cf5bc65614",
	Voided: "a0502a00-fa8a-42b5-ac3e-f17242c13ca7",
	Overdue: "c8971eae-42de-4bcc-9349-c10477763d95"
};

export const PaymentStatuses = [
	{
		name: "Complete",
		value: PaymentStatus.Complete
	},
	{
		name: "Failed",
		value: PaymentStatus.Failed
	},
	{
		name: "Pending",
		value: PaymentStatus.Pending
	},
	{
		name: "Voided",
		value: PaymentStatus.Voided
	},
	{
		name: "Overdue",
		value: PaymentStatus.Overdue
	},
];

export const OrderStatus = {
	OrderCreated: "83412650-9ef8-4858-b732-8905a7e98d2b",
	Pending: "6e101585-dc6b-48ff-9714-c48b81fecc37",
	PartiallyFulfilled: "877eac74-1e4f-4659-98dd-4d4f99aa82b1",
	Unfulfilled: "5870e75b-db99-4c32-8db1-797699640a62",
	Fulfilled: "28e5f50f-8ef2-42ba-bbb2-c2aa2b57b7ed",
	Cancelled: "0193C657-2457-D9F8-CC72-C67DDDC9BB2A"
};

export const OrderStatuses = [
	{
		name: "Created",
		value: OrderStatus.OrderCreated
	},
	{
		name: "Partially Fulfilled",
		value: OrderStatus.PartiallyFulfilled
	},
	{
		name: "Unfulfilled",
		value: OrderStatus.Unfulfilled
	},
	{
		name: "Fulfilled",
		value: OrderStatus.Fulfilled
	},
	{
		name: "Pending",
		value: OrderStatus.Pending
	},
	{
		name: "Cancelled",
		value: OrderStatus.Cancelled
	},
];

export const FulfillmentStatus = {
	Complete: "9f196a33-bc2c-4e47-b37d-6c69e5289da7",
	Unfulfilled: "4fc90e38-0653-4b79-8de7-557d517cc3e2",
	New: "5ab7f020-c141-4e03-a438-52c7e69999c3",
	Scheduled: "9bd94c55-9392-4073-adc5-f82fa62cf286",
	OnHold: "be1034c7-4bb0-4af6-a3b1-296ea5760d3a",
	AwaitingShipment: "c80d9052-ec08-4d30-96a3-4b8b88e0982a",
	ReadyForCollection: "622f7c7c-e763-4ce3-8ac9-b45b302f1c6d",
	Fulfilled: "b53d6d30-c261-481d-a363-19d4fc8ab627"
};

export const FulfillmentStatuses = [
	{
		name: "Complete",
		value: FulfillmentStatus.Complete
	},
	{
		name: "Unfulfilled",
		value: FulfillmentStatus.Unfulfilled
	},
	{
		name: "New",
		value: FulfillmentStatus.New
	},
	{
		name: "Scheduled",
		value: FulfillmentStatus.Scheduled
	},
	{
		name: "On Hold",
		value: FulfillmentStatus.OnHold
	},
	{
		name: "Awaiting Shipment",
		value: FulfillmentStatus.AwaitingShipment
	},
	{
		name: "Ready For Collection",
		value: FulfillmentStatus.ReadyForCollection
	},
	{
		name: "Fulfilled",
		value: FulfillmentStatus.Fulfilled
	},
];

export const OrderPaymentStatus = {
	Paid: "a618a2b1-9108-46fa-aa05-e05641a01c58",
	PartiallyPaid: "e5bdfa70-4478-4226-8b6e-b3066b1593e6",
	PendingPayment: "c07203c3-c4d3-4d80-8e3c-1d71efee0a3e",
	Unpaid: "7ca57b4f-6728-4084-b1b8-07f8f47aaa5d",
	Refunded: "a870998f-7a85-499c-bdeb-adb2add7a6c6",
	PartiallyRefunded: "8ca91f97-e616-45b3-b05e-7297546d5117",
	Cancelled: "0193C995-63BC-F0AF-7DA1-178AFA87624C"
};

export const OrderPaymentStatuses = [
	{
		name: "Paid",
		value: OrderPaymentStatus.Paid
	},
	{
		name: "Partially Paid",
		value: OrderPaymentStatus.PartiallyPaid
	},
	{
		name: "Pending Payment",
		value: OrderPaymentStatus.PendingPayment
	},
	{
		name: "Unpaid",
		value: OrderPaymentStatus.Unpaid
	},
	{
		name: "Refunded",
		value: OrderPaymentStatus.Refunded
	},
	{
		name: "Partially Refunded",
		value: OrderPaymentStatus.PartiallyRefunded
	},
	{
		name: "Cancelled",
		value: OrderPaymentStatus.Cancelled
	},
];

export const OrderFulfillmentStatus = {
	Complete: "a4472632-f647-491b-9a5b-b928cc1d2d44",
	PartiallyUnfulfilled: "896235da-eab2-47b2-ba19-c19fd8d2bc90",
	Unfulfilled: "bac6f9e7-73b2-4051-89e5-31721125a80b",
	Scheduled: "f7cf9795-298a-4746-aee2-add7ae67e0da",
	OnHold: "07bc08b7-ca8a-4b4e-92af-dd29facabafa",
	New: "40a58f90-472f-4d71-8b86-47395d1e1599",
	AwaitingShipment: "e8bfed83-fc64-407d-8516-78f1365113ac",
	ReadyForCollection: "429960e8-f858-4021-8ef7-b13072c62423",
	Fulfilled: "8f92fde6-c93f-480e-8acb-d2c1b5299712",
	Cancelled: "0193CA05-F208-8DF2-76F0-1972800B64C9"
};

export const OrderFulfillmentStatuses = [
	{
		name: "Complete",
		value: OrderFulfillmentStatus.Complete
	},
	{
		name: "Partially Unfulfilled",
		value: OrderFulfillmentStatus.PartiallyUnfulfilled
	},
	{
		name: "Unfulfilled",
		value: OrderFulfillmentStatus.Unfulfilled
	},
	{
		name: "Scheduled",
		value: OrderFulfillmentStatus.Scheduled
	},
	{
		name: "On Hold",
		value: OrderFulfillmentStatus.OnHold
	},
	{
		name: "New",
		value: OrderFulfillmentStatus.New
	},
	{
		name: "Awaiting Shipment",
		value: OrderFulfillmentStatus.AwaitingShipment
	},
	{
		name: "Ready For Collection",
		value: OrderFulfillmentStatus.ReadyForCollection
	},
	{
		name: "Fulfilled",
		value: OrderFulfillmentStatus.Fulfilled
	},
	{
		name: "Cancelled",
		value: OrderFulfillmentStatus.Cancelled
	},
];

export const PaymentConfirmationMessage = {
	HeadingComplete: "Order completed successfully!",
	HeadingPartialComplete: "Payment completed successfully!",
	HeadingFailed: "Something went wrong!",
	HeadingPending: "Awaiting payment",
	MessageComplete: "Your order is accepted",
	MessagePartialComplete: "Please pay the balance to complete your order",
	MessageFailed: "Payment could not be completed",
	MessagePending: "Your order will be fulfilled once payment is received"
};

export const OrderLogisticsStatus = {
	Ready: "89e24fd2-8d68-4a2a-aadc-2021c2133570",
	Pending: "7337a2a0-73c7-4a2c-86cc-2e29f1a0b754",
	MethodChosen: "090c4d3d-9b63-466b-956d-4c0a3d705f68"
};

export const AccountType = {
	Trade: "trade",
	Retail: "retail",
	Guest: "guest"
};

export const DeliveryMethod = {
	Collection: "pickup",
	Ship: "ship"
};

export const LogisticsMethod = {
	Collection: "4C2D06AB-F3A7-4308-9541-7A9AC7F6D18C",
	Ship: "CBD12DBB-8F07-4042-BF0C-EF2121AAAD88"
};

export const PaymentMethod = {
	AccountCredit: "account_credit",
	PayFast: "payfast",
	EFT: "eft",
	CardOnCollection: "card_on_collection"
};

export const AddressType = {
	Billing: Symbol(1000),
	Shipping: Symbol(1001),
	Collection: Symbol(1002)
};

export const default_ship_via = "155";
export const ProductAvailability = {
	InStock: "In Stock",
	LowStock: "Low Stock",
	ThreeToFiveDays: "3-5 Days",
	No_Stock: "No Stock"
};

export const PayfastResponsePaymentStatus = {
	Complete: "COMPLETE",
	Cancelled: "CANCELLED"
};

export const PageStatus = {
	NotLoaded: Symbol(1000),
	AuthCheckStarted: Symbol(1001),
	AuthChecksFailed: Symbol(1002),
	AuthChecksCompleteDataFetchNotStarted: Symbol(1003),
	DataFetchStarted: Symbol(1004),
	DataFetchComplete: Symbol(1005),
	DataFetchFailed: Symbol(1006)
};


export const ApiCallStatus = Object.freeze({
	NotStarted: Symbol(2000),
	InProgress: Symbol(2001),
	Succeeded: Symbol(2002),
	Failed: Symbol(2003),
	Error: Symbol(2004),
	ReSend: Symbol(2005)
});

export const ApiCallResult = Object.freeze({
	NoResult: Symbol(3000),
	Success: Symbol(3001),
	Failed: Symbol(3002),
	Error: Symbol(3003)
});

export const PriceCategory = {
	Retail: "ret",
	Trade: "tra",
	Wholesale: "ws1",
	Wholesale2: "ws2",
	Wholesale3: "ws3",
	Export: "exp"
};

export const FormType = Object.freeze({
	Create: Symbol(1000),
	Edit: Symbol(1001)
});

export const AnnouncementType = Object.freeze({
	Informational: 1,
	Acknowledgement: 2,
	Promotional: 3
});

export const ApiHeaders = Object.freeze({
	WebsiteId: "ahwid"
});