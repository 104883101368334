import React, { useState } from "react";
import {
	Box,
	Button,
	Grid,
	IconButton,
	Menu,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	Paper, Divider, Card, CardContent, TextField, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import { Link } from "react-router-dom";
import { SouthAfricanRand } from "../../utils/helpers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { OrderPaymentStatus, OrderPaymentStatuses, OrderStatuses } from "../../utils/constants";
import MarkOrderAsPaidModal from "../MarkOrderAsPaidModal";
import moment from "moment";
import { find } from "lodash";

const Orders = ({
	onBackClick,
	onPageNumberChange,
	pageNumber,
	pageSize,
	totalResults,
	orders,
	salesPeople,
	orderToEdit,
	setOrderToEdit,
	showMarkAsPaidModal,
	openMarkAsPaidModal,
	closeModals,
	handleMarkAsPaidModalClose,
	reloadPage,
	onApplyFilters
}) => {
	const [salesPersonCode, setSalesPersonCode] = useState('');
	const [orderStatusId, setOrderStatusId] = useState('');
	const [orderPaymentStatusId, setOrderPaymentStatusId] = useState('');
	const [sortColumn, setSortColumn] = useState('dateCreated');
	const [sortDirection, setSortDirection] = useState('DESC');

	const applyFilters = () => {
		onApplyFilters({
			salesPersonCode,
			orderStatusId,
			orderPaymentStatusId,
			sortColumn,
			sortDirection
		});
	};

	const resetFilters = () => {
		setSalesPersonCode("");
		setOrderStatusId("");
		setOrderPaymentStatusId("");
		setSortColumn("");
		setSortDirection("");

		onApplyFilters({
			salesPersonCode: "",
			orderStatusId: "",
			orderPaymentStatusId: "",
			sortColumn: "",
			sortDirection: ""
		});
	};

	return (
		<Box sx={{ minHeight: "100vh", padding: 3 }}>
			<Typography variant="h5" mb={2}>Order Management</Typography>
			<Divider sx={{ mb: 3 }} />

			{/* Filter and Sort Controls */}
			<Card sx={{ mb: 4 }}>
				<CardContent>
					<Typography variant="subtitle1" gutterBottom>
						Filter & Sort Orders
					</Typography>
					<Divider sx={{ mb: 2 }} />
					<Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
						{!!salesPeople &&
							<Grid item xs={12} sm={3}>
								<FormControl fullWidth>
									<InputLabel>Filter by Sales Person</InputLabel>
									<Select
										value={salesPersonCode}
										onChange={(e) => setSalesPersonCode(e.target.value)}
									>
										{salesPeople.map((s, index) => {
											return (
												<MenuItem key={index} value={s.code}>{s.name}</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
						}
						<Grid item xs={12} sm={3}>
							<FormControl fullWidth>
								<InputLabel>Filter by Order Status</InputLabel>
								<Select
									value={orderStatusId}
									onChange={(e) => setOrderStatusId(e.target.value)}
								>
									{OrderStatuses.map((s, index) => {
										return (
											<MenuItem key={index} value={s.value}>{s.name}</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={3}>
							<FormControl fullWidth>
								<InputLabel>Filter by Payment Status</InputLabel>
								<Select
									value={orderPaymentStatusId}
									onChange={(e) => setOrderPaymentStatusId(e.target.value)}
								>
									{OrderPaymentStatuses.map((s, index) => {
										return (
											<MenuItem key={index} value={s.value}>{s.name}</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={3} />
						<Grid item xs={12} sm={3}>
							<FormControl fullWidth>
								<InputLabel>Sort By</InputLabel>
								<Select
									value={sortColumn}
									onChange={(e) => setSortColumn(e.target.value)}
								>
									<MenuItem value="dateCreated">Order Date</MenuItem>
									<MenuItem value="orderStatus">Order Status</MenuItem>
									<MenuItem value="paymentStatus">Payment Status</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={3}>
							<FormControl fullWidth>
								<InputLabel>Sort Direction</InputLabel>
								<Select
									value={sortDirection}
									onChange={(e) => setSortDirection(e.target.value)}
								>
									<MenuItem value="ASC">Ascending</MenuItem>
									<MenuItem value="DESC">Descending</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} display="flex" justifyContent="center">
							<Button variant="contained" color="primary" onClick={applyFilters} sx={{ mr: 2 }}>
								Apply Filters
							</Button>
							<Button variant="outline" color="error" onClick={resetFilters}>
								Clear Filters
							</Button>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			{/* Orders Table */}
			{!!orders && (
				<TableContainer component={Paper} elevation={3}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align="left">#</TableCell>
								<TableCell align="left">Customer</TableCell>
								<TableCell align="left">Date</TableCell>
								<TableCell align="left">Sales Person</TableCell>
								<TableCell align="left">Order Status</TableCell>
								<TableCell align="left">Payment Status</TableCell>
								<TableCell align="left">Amount</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{orders.map((order, index) => {
								const salesPerson = find(salesPeople, (sp) => sp.code === order.salesPersonCode);

								return (
									<TableRow key={index} hover>
										<TableCell>
											<Link to={`/order/${order.orderId}`} style={{ color: "blue" }}>
												{order.reference}
											</Link>
										</TableCell>
										<TableCell>{order.customerName}</TableCell>
										<TableCell>{moment(order.dateCreated).format("YYYY-MM-DD | HH:mm:ss")}</TableCell>
										<TableCell>{salesPerson?.name ?? order.salesPersonCode}</TableCell>
										<TableCell>{order.orderStatus}</TableCell>
										<TableCell>{order.paymentStatus}</TableCell>
										<TableCell>{SouthAfricanRand.format(order.total)}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			)}

			{/* Pagination */}
			<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
				{totalResults > 0 && (
					<Pagination
						count={Math.ceil(totalResults / pageSize)}
						page={pageNumber}
						onChange={onPageNumberChange}
						color="primary"
						size="small"
					/>
				)}
			</Box>

			{!!showMarkAsPaidModal && (
				<MarkOrderAsPaidModal
					open={showMarkAsPaidModal}
					setOpen={openMarkAsPaidModal}
					setClose={closeModals}
					handleModalClose={handleMarkAsPaidModalClose}
					orderToEdit={orderToEdit}
					reloadPage={reloadPage}
					salesPeople={salesPeople}
				/>
			)}
		</Box>
	);
};

export default Orders;
