import React from "react";
import { Grid } from "semantic-ui-react";
import OrderCreateEditForm from "../../forms/OrderCreateEditForm";
import TecfinityAccountLinkModal from "../TecfinityAccountLinkModal";
import RetrySalesOrderModal from "../RetrySalesOrderModal";
import MarkOrderAsPaidModal from "../MarkOrderAsPaidModal";
import CancelOrderModal from "../CancelOrderModal";

const OrderCreateEdit = ({
	order,
	provinces,
	countries,
	salesPeople,
	billingAddresses,
	shippingAddresses,
	onBackClick,
	onRetrySaleOrderClick,
	onMarkAsPaidClick,
	onCancelOrderClick,
	openModal,
	salesOrderRequestId,
	closeModals,
	showRetrySaleOrderModal,
	showMarkAsPaidModal,
	showCancelOrderModal,
	reloadPage
}) => {
	return (
		<div>
			<Grid container>
				<Grid.Row verticalAlign="middle" textAlign="center">
					{!!order &&
						<OrderCreateEditForm
							order={order}
							provinces={provinces}
							countries={countries}
							billingAddresses={billingAddresses}
							shippingAddresses={shippingAddresses}
							salesPeople={salesPeople}
							goBack={onBackClick}
							onRetrySaleOrderClick={onRetrySaleOrderClick}
							onMarkAsPaidClick={onMarkAsPaidClick}
							onCancelOrderClick={onCancelOrderClick}
						/>
					}
				</Grid.Row>
			</Grid>
			{!!showRetrySaleOrderModal &&
				<RetrySalesOrderModal
					salesOrderRequestId={salesOrderRequestId}
					open={showRetrySaleOrderModal}
					setClose={closeModals}
				/>
			}
			{!!showMarkAsPaidModal && (
				<MarkOrderAsPaidModal
					open={showMarkAsPaidModal}
					setClose={closeModals}
					orderToEdit={order}
					reloadPage={reloadPage}
					salesPeople={salesPeople}
				/>
			)}
			{!!showCancelOrderModal && (
				<CancelOrderModal
					open={showCancelOrderModal}
					setClose={closeModals}
					order={order}
					reloadPage={reloadPage}
					salesPeople={salesPeople}
				/>
			)}
		</div>
	);
};

export default OrderCreateEdit;