import React, { useState } from "react";
import {
	Box,
	Button,
	Typography,
	Modal,
	Grid,
	CircularProgress
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArgusLogo from "../../img/logo.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { cancelOrder } from "../../main-component/PageFunctions/orderFunctions";
import { find } from "lodash";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minHeight: "50%",
	width: "400px",
	bgcolor: "#FFFFFF",
	boxShadow: 24,
	p: 4,
	borderRadius: 2,
};

const modalState = {
	orderDetails: 1,
	inProgress: 2,
	orderCancelled: 3,
	orderNotCancelled: 4,
	error: 5,
};

const CancelModal = ({ open, setClose, order, salesPeople, reloadPage }) => {
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [currentModalState, setCurrentModalState] = useState(modalState.orderDetails);
	const [modalError, setModalError] = useState("");
	const salesPerson = find(salesPeople, (x) => x.code === order.salesPersonCode);

	const cancelOrderAsync = async (orderId) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await cancelOrder({
			auth0Token,
			orderId,
			setStatusInProgress: () => setCurrentModalState(modalState.inProgress),
			setStatusSuccess: () => setCurrentModalState(modalState.orderCancelled),
			setStatusFailed: () => setCurrentModalState(modalState.orderNotCancelled),
			setStatusError: () => {
				setCurrentModalState(modalState.error);
				setModalError("Could not cancel order");
			},
		});
	};

	const resetModal = () => {
		setCurrentModalState(modalState.orderDetails);
		setModalError("");
	};

	const closeModal = (refreshPage = false) => {
		resetModal();
		if (refreshPage) reloadPage();
		setClose();
	};

	return (
		<Modal open={open} onClose={closeModal}>
			<Box sx={modalStyle}>
				<Box textAlign="center" mb={2}>
					<img src={ArgusLogo} alt="Argus Logo" style={{ height: 50 }} />
					<Typography variant="h6" mt={2}>Cancel Order</Typography>
				</Box>

				{/* Order Details or Confirmation */}
				{(currentModalState === modalState.orderDetails || currentModalState === modalState.inProgress) && (
					<Box>
						<Typography variant="body2" mb={2}>
							Are you sure you want to cancel this order:
						</Typography>
						<Box mb={2}>
							<Typography variant="body2"><b>Order Number:</b> {order.reference}</Typography>
							<Typography variant="body2"><b>Customer:</b> {order.customerName}</Typography>
							<Typography variant="body2"><b>Sales Person:</b> {salesPerson?.name ?? order.salesPersonCode}</Typography>
						</Box>

						{/* Action Buttons */}
						<Grid container spacing={2} justifyContent="flex-end">
							<Grid item>
								<Button onClick={closeModal} color="error" disabled={currentModalState === modalState.inProgress}>
									Cancel
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									onClick={() => cancelOrderAsync(order.orderId)}
									disabled={currentModalState === modalState.inProgress}
									startIcon={currentModalState === modalState.inProgress && <CircularProgress size="1rem" />}
								>
									Confirm
								</Button>
							</Grid>
						</Grid>
					</Box>
				)}

				{/* Success Message */}
				{currentModalState === modalState.orderCancelled && (
					<Box textAlign="center">
						<CheckCircleOutlineIcon color="success" fontSize="large" />
						<Typography variant="h5" mt={2}>Success</Typography>
						<Typography variant="body2">Order cancelled.</Typography>
						<Button variant="contained" color="primary" onClick={() => closeModal(true)} sx={{ mt: 2 }}>
							Close
						</Button>
					</Box>
				)}

				{/* Failure Message */}
				{currentModalState === modalState.orderNotCancelled && (
					<Box textAlign="center">
						<CancelIcon color="error" fontSize="large" />
						<Typography variant="h5" mt={2}>Failed</Typography>
						<Typography variant="body2">Could not cancel order.</Typography>
						<Button variant="contained" color="primary" onClick={closeModal} sx={{ mt: 2 }}>
							Close
						</Button>
					</Box>
				)}

				{/* Error Message */}
				{currentModalState === modalState.error && modalError && (
					<Box textAlign="center">
						<ErrorOutlineIcon color="error" fontSize="large" />
						<Typography variant="h5" mt={2}>Error</Typography>
						<Typography variant="body2">{modalError}</Typography>
						<Button variant="contained" color="primary" onClick={closeModal} sx={{ mt: 2 }}>
							Close
						</Button>
					</Box>
				)}
			</Box>
		</Modal>
	);
};

export default CancelModal;
